<template>
  <transition name="fade-in-up">
    <router-view :key="$route.fullPath"></router-view>
  </transition>
</template>

<script>
  export default {
    name: 'RecipeBakery',
    components: {}
  };
</script>

<style lang="scss"></style>
